import { Calendar, LineChart, PawPrint, Users } from 'lucide-react'
import { Avatar, AvatarFallback, AvatarImage } from '~/components/ui/avatar'
import { Card, CardContent, CardHeader, CardTitle } from '~/components/ui/card'

export default function Tablero() {
  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 mb-6">Dashboard</h1>

      {/* Stats */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Citas Hoy</CardTitle>
            <Calendar className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">12</div>
            <p className="text-xs text-muted-foreground">+2 desde ayer</p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">
              Pacientes Totales
            </CardTitle>
            <Users className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">1,284</div>
            <p className="text-xs text-muted-foreground">+8% este mes</p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">
              Ingresos Mensuales
            </CardTitle>
            <LineChart className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">$12,450</div>
            <p className="text-xs text-muted-foreground">
              +12% desde el mes pasado
            </p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Satisfacción</CardTitle>
            <PawPrint className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">98%</div>
            <p className="text-xs text-muted-foreground">
              +2% desde el mes pasado
            </p>
          </CardContent>
        </Card>
      </div>

      {/* Appointments */}
      <Card className="mb-6">
        <CardHeader>
          <CardTitle>Próximas Citas</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {[
              {
                time: '10:00 AM',
                pet: 'Max',
                owner: 'Juan Pérez',
                type: 'Chequeo',
              },
              {
                time: '11:30 AM',
                pet: 'Luna',
                owner: 'María González',
                type: 'Vacunación',
              },
              {
                time: '2:00 PM',
                pet: 'Rocky',
                owner: 'Carlos Rodríguez',
                type: 'Cirugía',
              },
            ].map((appointment, index) => (
              <div
                key={index}
                className="flex items-center justify-between border-b pb-2 last:border-0 last:pb-0"
              >
                <div className="flex items-center space-x-3">
                  <Avatar>
                    <AvatarImage
                      // src={`/placeholder.svg?height=32&width=32&text=${appointment.pet}`}
                      alt={appointment.pet}
                    />
                    <AvatarFallback>{appointment.pet[0]}</AvatarFallback>
                  </Avatar>
                  <div>
                    <p className="font-medium">{appointment.pet}</p>
                    <p className="text-sm text-muted-foreground">
                      {appointment.owner}
                    </p>
                  </div>
                </div>
                <div className="text-right">
                  <p>{appointment.time}</p>
                  <p className="text-sm text-muted-foreground">
                    {appointment.type}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>

      {/* Activity Chart */}
      <Card>
        <CardHeader>
          <CardTitle>Actividad Reciente</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-[200px] flex items-center justify-center text-muted-foreground">
            Gráfico de actividad (implementar con librería de gráficos)
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>Actividad Reciente</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-[200px] flex items-center justify-center text-muted-foreground">
            Gráfico de actividad (implementar con librería de gráficos)
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>Actividad Reciente</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-[200px] flex items-center justify-center text-muted-foreground">
            Gráfico de actividad (implementar con librería de gráficos)
          </div>
        </CardContent>
      </Card>
    </>
  )
}
